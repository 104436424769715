<template>
    <main class="dosiin_home home-page main-page styleMainPage">
        <div class="box-wrapper-pc dosiin_padding-header-top dosiin_pd-top-24">
            <div class="box-grid-pc">
                <div class="box-grid-pc_left">
                    <div class="dosiin-categories-topic-detail">
                        <div class="dosiin-categories-topic">
                            <div class="header-post">
                                <div class="avatar-post">
                                    <img :src="_topicIcon" width="36" height="36" :alt="category.category_name">
                                </div>
                                <div class="detail-avatar dosiin_ml-2">
                                    <div class="name-detail">{{category.category_name}}</div>
                                </div>
                            </div>

                            <div class="view-all">
                                <span class="view">Hiển thị</span>
        
                                <a class="categories-list" @click="changeGrid('normal')">
                                    <i :class="['dsi' , style == 'normal' ? 'dsi-act-fill-grid' : 'dsi-fill-grid']"></i>
                                </a>
                                
                                <a class="categories-list" @click="changeGrid('special')">
                                    <i :class="['dsi' , style == 'special' ? 'dsi-act-fill-radiolist' : 'dsi-fill-radiolist']"></i>
                                </a>
                            </div>
                        </div>

                        <div class="dosiin-line-left"></div>

                        <PostsLoader 
                            :key="catId"
                            :style="style"
                            ref="postLoader" 
                            :params="{'sort_by':'random' , 'catId' : catId , 'timestamp' : new Date().getTime()}"
                            @total-post="updateTotals"
                        />

                    </div>
                </div>

                <div class="box-grid-pc_right">
                    <GroupTags />
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import PostsLoader from '@/components/post/PostLoader.vue';
    import GroupTags from '@/components/hashtag/GroupTags.vue';
    import { ref , computed , watch } from 'vue';
    import { useRoute } from 'vue-router';
    import { useStore } from 'vuex'
    import TagService from '@/services/tag';
    import { _dsEmptyObject } from '@/utlis/helpers';

    export default {
        setup(){
            const route = useRoute();
            const store = useStore();
            const style = ref('special');
            const catId = ref(route.query.catId);
            const category   = ref({});
            getCatTag();

            function changeGrid(grid){
                style.value = grid;
            }

            watch(() => route.query,
                async query => {
                    if(_dsEmptyObject(query) || query.catId == 'undefined') {
                        return;
                    }

                    catId.value = query.catId;

                    getCatTag();
                }
            );

            async function getCatTag(){
                const response = await TagService.fetchGroupTagsWithPost(catId.value);
                category.value = response.data;
            }

            return {
                style,
                catId,
                category,
                changeGrid,
                postLoader: computed(() => store.state.PostsStore.postLoader),
            }
        },
        components:{
            PostsLoader,
            GroupTags
        },
        methods:{
            updateTotals(totals){
                this.post_count = totals;
            }
        }
    }
</script>
