<template>
    <div>
        <header class="dosiin_header-topic-detail" style="display: unset;">
            <div class="back-header dosiin_back-header-sticky">
                <div class="go-back-icon" @click="goBack()">
                    <i class="dsi dsi-chevron-left"></i>
                    <a class="text">Quay lại</a>
                </div>
                <h2 class="text-grey-lg center">
                    <img :src="_topicIcon" width="15" height="15" :alt="category.category_name">
                    {{category.category_name}}
                    <div class="sub-title-header" style="display:block;"> {{ numberFormat(postCount)}} bài viết</div>
                </h2>
                <div class="back-header-right-side">
                    <div class="toggle-view-buttons">
                        <!-- <span :class="['dosiin_grid-icon-style grid-icon', isActiveGrip]" @click="style='normal'"></span> -->
                        <span :class="['dosiin_list-icon-style list-icon' , isActiveGripSpecial]" @click="style='special'"></span>
                    </div>
                </div>
            </div>
        </header>
        <main class="dosiin_home home-page main-page dosiin_topic-detail">
            <PostsLoader 
                :key="catId"
                :style="style"
                ref="postLoader" 
                :params="{'sort_by':'random' , 'catId' : catId , 'timestamp' : new Date().getTime()}"
                @total-post="total => postCount = total"
            />
        </main>
    </div>
</template>

<script>
    import PostsLoader from '@/components/post/PostLoader.vue';
    import { ref , computed , watch } from 'vue';
    import { useRoute } from 'vue-router';
    import { useStore } from 'vuex'
    import TagService from '@/services/tag';
    import { _dsEmptyObject } from '@/utlis/helpers';

    export default {
        components:{
            PostsLoader
        },
        setup(){
            const route = useRoute();
            const store = useStore();
            const style = ref('special');
            const catId = ref(route.query.catId);
            const category   = ref({});
            const postCount = ref(0);

            getCatTag();

            function changeGrid(grid){
                style.value = grid;
            }

            watch(() => route.query,
                async query => {
                    if(_dsEmptyObject(query) || query.catId == 'undefined') {
                        return;
                    }

                    catId.value = query.catId;

                    getCatTag();
                }
            );

            async function getCatTag(){
                const response = await TagService.fetchGroupTagsWithPost(catId.value);
                category.value = response.data;
            }

            return {
                style,
                catId,
                category,
                changeGrid,
                postCount,
                postLoader: computed(() => store.state.PostsStore.postLoader),
            }
        },
    }
</script>